
<div class="scroll-thumb" style="overflow-x: scroll;overflow-y: hidden;z-index: 1000;position:absolute;bottom:0;"></div>
<div id="regCompany">
<div style="position: absolute; top: 0; left: 0; width: 100%">
  <nav
    class="navbar navbar-expand navbar-color navbar-light form-hd-1"
    style="margin-left: 0px; height: 70px; padding-left: 0;"
  >
    <a [routerLink]="['/']">
      <img
        src={{logoUrl}}
        alt="AdminLTE Logo"
        class="brand-image"
        style="margin-left: 20px;"
    /></a>
  </nav>
  <!-- Main content -->
  <!-- @@@@@@@@@@@@@@@@@@@@@@@@@ -->
  <!-- -->
  <!-- -->
  <!-- 法人情報をご入力ください。-->
  <!-- -->
  <!-- -->
  <!-- @@@@@@@@@@@@@@@@@@@@@@@@@ -->
    <div class="container-fluid">
      <!-- STEP -->
      <div class="col-md-12 left main-top form-wd-1">
        <div
          class="col-6 col-md-6 col-xl-6 steps"
          style="flex: 0 0 45%; max-width: 45%"
        >
          <li class="active">
            <p>
              {{ 'COMPANY_REGISTER.STEP' | translate }}
              <strong>{{ 'COMPANY_REGISTER.STEP_1' | translate }}</strong>
            </p>
          </li>
        </div>
        <div
          class="col-6 col-md-6 col-xl-6 steps steps-s step-left"
          style="flex: 0 0 45%; max-width: 45%"
        >
          <li class="done">
            <p>
              {{ 'COMPANY_REGISTER.STEP' | translate }}
              <strong>{{ 'COMPANY_REGISTER.STEP_2' | translate }}</strong>
            </p>
          </li>
        </div>
        <!-- <div class="col-3 col-md-3 col-xl-3 steps steps-s step-left">
              <li class="done"><p>step <strong>03</strong></p></li>
            </div> -->
      </div>
      <h3 class="header-bottom form-wd-1" style="margin-left: 5%; margin-right: 5%;">
        {{ 'COMPANY_REGISTER.TITLE_1' | translate }}
      </h3>
      <div class="row">
        <div class="col-md-10 offset-lg-1 offset-md-1 offset-1">
          <!-- 1 -->
          <div class="card-body">
            <div class="row detail">
              <div class="col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-dest">
                <h3 class="card-title">
                  {{ 'COMPANY_REGISTER.COMPANY_REGISTER_STOCK' | translate }}
                </h3>
              </div>
              <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-dest1">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                  <span class="badge badge-info">{{
                    'COMMON.COMMON_REQUIRE_LABEL_1' | translate
                  }}</span>
                  <div
                    class="custom-control custom-radio display"
                    style="padding-left: 10px; display: contents"
                  >
                    <!-- 1.1 -->
                    <div
                      class="custom-control custom-radio display"
                      style="margin-left: 20px"
                      (click)="selectedRadios('1')"
                      *ngIf="corporate_status == '1'"
                    >
                      <input
                        class="custom-control-input custom-control-input-danger custom-control-input-outline"
                        type="radio"
                        checked
                        id="customRadio4"
                        name="customRadio2"
                        value="{{
                          'COMPANY_REGISTER.COMPANY_REGISTER_PREV_STOCK'
                            | translate
                        }}"
                      />
                      <label for="customRadio4" class="custom-control-label">{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_PREV_STOCK'
                          | translate
                      }}</label>
                    </div>
                    <!-- 1.1 -->
                    <div
                      class="custom-control custom-radio display"
                      style="margin-left: 20px"
                      (click)="selectedRadios('1')"
                      *ngIf="corporate_status != '1'"
                    >
                    <input
                      class="custom-control-input custom-control-input-danger custom-control-input-outline"
                      type="radio"
                      id="customRadio4"
                      name="customRadio2"
                      value="{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_PREV_STOCK'
                          | translate
                      }}"
                    />
                    <label for="customRadio4" class="custom-control-label">{{
                      'COMPANY_REGISTER.COMPANY_REGISTER_PREV_STOCK'
                        | translate
                    }}</label>
                  </div>
                    <!-- 1.2 -->
                    <div
                      class="custom-control custom-radio display"
                      style="margin-left: 20px"
                      (click)="selectedRadios('2')"
                      *ngIf="corporate_status == '2'"
                    >
                      <input
                        class="custom-control-input custom-control-input-danger custom-control-input-outline"
                        type="radio"
                        id="customRadio5"
                        name="customRadio2"
                        checked
                        value="{{
                          'COMPANY_REGISTER.COMPANY_REGISTER_NEXT_STOCK'
                            | translate
                        }}"
                      />
                      <label for="customRadio5" class="custom-control-label">{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_NEXT_STOCK'
                          | translate
                      }}</label>
                    </div>
                    <!-- 1.2 -->
                    <div
                      class="custom-control custom-radio display"
                      style="margin-left: 20px"
                      (click)="selectedRadios('2')"
                      *ngIf="corporate_status != '2'"
                    >
                      <input
                        class="custom-control-input custom-control-input-danger custom-control-input-outline"
                        type="radio"
                        id="customRadio5"
                        name="customRadio2"
                        value="{{
                          'COMPANY_REGISTER.COMPANY_REGISTER_NEXT_STOCK'
                            | translate
                        }}"
                      />
                      <label for="customRadio5" class="custom-control-label">{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_NEXT_STOCK'
                          | translate
                      }}</label>
                    </div>
                    <!-- 1.3 -->
                    <div
                      class="custom-control custom-radio display radio-top"
                      style="margin-left: 20px"
                      (click)="selectedRadios('3')"
                      *ngIf="corporate_status == '3'"
                    >
                      <input
                        class="custom-control-input custom-control-input-danger custom-control-input-outline"
                        type="radio"
                        id="customRadio6"
                        name="customRadio2"
                        checked
                        value="{{
                          'COMPANY_REGISTER.COMPANY_REGISTER_OTHER_STOCK'
                            | translate
                        }}"
                      />
                      <label for="customRadio6" class="custom-control-label">{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_OTHER_STOCK'
                          | translate
                      }}</label>
                    </div>
                    <!-- 1.3 -->
                    <div
                      class="custom-control custom-radio display radio-top"
                      style="margin-left: 20px"
                      (click)="selectedRadios('3')"
                      *ngIf="corporate_status != '3'"
                    >
                      <input
                        class="custom-control-input custom-control-input-danger custom-control-input-outline"
                        type="radio"
                        id="customRadio6"
                        name="customRadio2"
                        value="{{
                          'COMPANY_REGISTER.COMPANY_REGISTER_OTHER_STOCK'
                            | translate
                        }}"
                      />
                      <label for="customRadio6" class="custom-control-label">{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_OTHER_STOCK'
                          | translate
                      }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 2 -->
          <div class="card-body">
            <div class="row detail">
              <div class="col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-dest">
                <h3 class="card-title">
                  {{
                    'COMPANY_REGISTER.COMPANY_REGISTER_COMPANY_NAME' | translate
                  }}
                </h3>
              </div>
              <div class="col-12 col-md-8 col-xl-8 col-dest1">
                <div class="col-12">
                  <span class="badge badge-info">{{
                    'COMMON.COMMON_REQUIRE_LABEL_1' | translate
                  }}</span>
                  <div class="custom-control display col-xl-10 col-10">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_COMPANY_NAME_PLACEHOLDER'
                          | translate
                      }}"
                      name="companyName"
                      [(ngModel)]="companyName"
                      (ngModelChange)="change()"
                      maxlength="50"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row detail top">
              <div class="col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-dest"></div>
              <div class="col-12 col-md-8 col-xl-8  col-dest1">
                <div class="col-12">
                  <span class="badge badge-info">{{
                    'COMMON.COMMON_REQUIRE_LABEL_1' | translate
                  }}</span>
                  <div class="custom-control display col-xl-10 col-10">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_COMPANY_NAME2_PLACEHOLDER'
                          | translate
                      }}"
                      name="companyNameKana"
                      [(ngModel)]="companyNameKana"
                      (ngModelChange)="onKeyKana(companyNameKana)"
                      autocomplete="off"
                      maxlength="50"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 3 -->
          <div class="card-body">
            <!-- 3.1 -->
            <div class="row detail">
              <div class="col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2  col-dest">
                <h3 class="card-title">
                  {{ 'COMPANY_REGISTER.COMPANY_REGISTER_ADDRESS' | translate }}
                </h3>
              </div>
              <div class="col-12 col-md-8 col-xl-8  col-dest1">
                <div class="col-12">
                  <span class="badge badge-info">{{
                    'COMMON.COMMON_REQUIRE_LABEL_1' | translate
                  }}</span>
                  <div class="custom-control display col-10 col-md-4 col-xl-4">
                    <input
                      type="text"
                      class="form-control"
                      maxlength="7"
                      placeholder="{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_ADDRESS_PLACEHOLDER'
                          | translate
                      }}"
                      name="postcode"
                      [(ngModel)]="postcode"
                      (ngModelChange)="change()"
                      (keyup)="onKey($event.target.value)"
                      onkeypress="return (event.charCode != 69 && event.charCode != 101) && (event.charCode < 42 && 8 <= event.charCode) || (event.charCode <= 57 && 47 < event.charCode)"
                   />
                  </div>
                  <div class="display">
                    <h3 class="font">
                      {{
                        'COMPANY_REGISTER.COMPANY_REGISTER_ADDRESS_DESC'
                          | translate
                      }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <!-- 3.2 -->
            <div class="row detail top">
              <div class="col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2  col-dest"></div>
              <div class="col-12 col-md-8 col-xl-8  col-dest1">
                <div class="col-12">
                  <span class="badge badge-info">{{
                    'COMMON.COMMON_REQUIRE_LABEL_1' | translate
                  }}</span>
                  <div class="custom-control display col-10 col-xl-10">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_CITY_PLACEHOLDER'
                          | translate
                      }}"
                      name="address_1"
                      [(ngModel)]="address_1"
                      (ngModelChange)="change()"
                      maxlength="50"
                    />
                  </div>
                </div>
                <div class="col-12 top">
                  <span class="badge badge-info">{{
                    'COMMON.COMMON_REQUIRE_LABEL_1' | translate
                  }}</span>
                  <div class="custom-control display col-10 col-xl-10">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_DISTRICT_PLACEHOLDER'
                          | translate
                      }}"
                      name="address_2"
                      [(ngModel)]="address_2"
                      (ngModelChange)="change()"
                      maxlength="20"
                    />
                  </div>
                </div>
                <div class="col-12 top lef">
                  <div class="custom-control display col-10 col-xl-10">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_BUILDING_PLACEHOLDER'
                          | translate
                      }}"
                      name="address_3"
                      [(ngModel)]="address_3"
                      (ngModelChange)="change()"
                      maxlength="30"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 4 -->
          <div class="card-body">
            <div class="row detail">
              <div class="col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-dest">
                <h3 class="card-title">
                  {{ 'COMPANY_REGISTER.COMPANY_REGISTER_PHONE' | translate }}
                </h3>
              </div>
              <div class="col-12 col-md-8 col-xl-8 col-dest1">
                <div class="col-12">
                  <span class="badge badge-info">{{
                    'COMMON.COMMON_REQUIRE_LABEL_1' | translate
                  }}</span>
                  <div class="custom-control display col-xl-5 col-md-5 col-10">
                    <input
                      type="tel"
                      class="form-control"
                      placeholder="{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_PHONE_PLACEHOLDER'
                          | translate
                      }}"
                      name="telephone"
                      [(ngModel)]="telephone"
                      (ngModelChange)="change()"
                      maxlength="15"
                      onkeypress="return (event.charCode != 69 && event.charCode != 101) && (event.charCode < 42 && 8 <= event.charCode) || (event.charCode <= 57 && 47 < event.charCode)"
                    />
                  </div>
                  <div class="display">
                    <h3 class="font">
                      {{
                        'COMPANY_REGISTER.COMPANY_REGISTER_PHONE_DESC'
                          | translate
                      }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 業種・業態 -->
          <div class="card-body">
            <div class="row detail">
              <div class="col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-dest">
                <h3 class="card-title">
                  {{ 'COMPANY_REGISTER.COMPANY_BUSINESS_TYPE' | translate }}
                </h3>
              </div>
              <div class="col-12 col-md-8 col-xl-8 col-dest1">
                <div class="col-12">
                  <span class="badge badge-info">{{
                    'COMMON.COMMON_REQUIRE_LABEL_1' | translate
                  }}</span>
                  <div class="custom-control display col-10 col-xl-10">
                      <select class="form-control" [(ngModel)]="business_type" (change) = "changeBusiness()">
                        <option *ngFor="let i of BusinessType" value="{{i.code}}">
                          {{i.name}}
                        </option>
                      </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- 5 -->
          <div class="card-body">
            <div class="row detail">
              <div class="col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-dest">
                <h3 class="card-title">
                  {{ 'COMPANY_REGISTER.COMPANY_REGISTER_NAME' | translate }}
                </h3>
              </div>
              <div class="col-12 col-md-8 col-xl-8 col-dest1">
                <div class="col-12">
                  <span class="badge badge-info">{{
                    'COMMON.COMMON_REQUIRE_LABEL_1' | translate
                  }}</span>
                  <div class="custom-control display col-xl-5 col-5">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_HIRAGANA_LASTNAME_PLACEHOLDER'
                          | translate
                      }}"
                      name="lastname"
                      [(ngModel)]="lastname"
                      (ngModelChange)="change()"
                      maxlength="10"
                    />
                  </div>
                  <div class="display col-xl-5 col-5">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_HIRAGANA_FIRSTNAME_PLACEHOLDER'
                          | translate
                      }}"
                      name="firstname"
                      [(ngModel)]="firstname"
                      (ngModelChange)="change()"
                      maxlength="10"
                    />
                  </div>
                </div>
                <div class="col-12 top">
                  <span class="badge badge-info">{{
                    'COMMON.COMMON_REQUIRE_LABEL_1' | translate
                  }}</span>
                  <div class="custom-control display col-xl-5 col-5">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_KITAGANA_LASTNAME_PLACEHOLDER'
                          | translate
                      }}"
                      name="lastname_kana"
                      [(ngModel)]="lastname_kana"
                      (ngModelChange)="onKeyLastNameKana(lastname_kana)"
                      maxlength="20"
                    />
                  </div>
                  <div class="display col-xl-5 col-5">
                    <input
                      type="text"
                      class="form-control display"
                      placeholder="{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_KITAGANA_FIRSTNAME_PLACEHOLDER'
                          | translate
                      }}"
                      name="firstname_kana"
                      [(ngModel)]="firstname_kana"
                      (ngModelChange)="onKeyNameKana(firstname_kana)"
                      maxlength="20"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 6 -->
          <div class="card-body">
            <div class="row detail">
              <div class="col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-dest">
                <h3 class="card-title">
                  {{
                    'COMPANY_REGISTER.COMPANY_REGISTER_DEPARTMENT' | translate
                  }}
                </h3>
              </div>
              <div class="col-12 col-md-8 col-xl-8 col-dest1">
                <div class="col-12">
                  <span class="badge badge-secondary badge-secondary-1">{{
                    'COMMON.COMMON_REQUIRE_LABEL_2' | translate
                  }}</span>
                  <div class="custom-control display col-xl-5 col-5">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_DEPARTMENT_PLACEHOLDER'
                          | translate
                      }}"
                      name="staff_post"
                      [(ngModel)]="staff_post"
                      (ngModelChange)="change()"
                      maxlength="20"
                    />
                  </div>
                  <div class="display col-xl-5 col-5">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_DEPARTMENT_LOCATION_PLACEHOLDER'
                          | translate
                      }}"
                      name="staff_position"
                      [(ngModel)]="staff_position"
                      (ngModelChange)="change()"
                      maxlength="20"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 7 -->
          <div class="card-body">
            <div class="row detail">
              <div class="col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-dest">
                <h3 class="card-title">
                  {{
                    'COMPANY_REGISTER.COMPANY_REGISTER_MEMBER_COUNT' | translate
                  }}
                </h3>
              </div>
              <div class="col-12 col-md-8 col-xl-8 col-dest1">
                <div class="col-12">
                  <span class="badge badge-info">{{
                    'COMMON.COMMON_REQUIRE_LABEL_1' | translate
                  }}</span>
                  <div class="custom-control display col-xl-5 col-5">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_MEMBER_COUNT_PLACEHOLDER'
                          | translate
                      }}"
                      name="memberCount"
                      [(ngModel)]="memberCount"
                      (ngModelChange)="change()"
                      maxlength="6"
                      onkeypress="return (event.charCode != 69 && event.charCode != 101) && (event.charCode < 42 && 8 <= event.charCode) || (event.charCode <= 57 && 47 < event.charCode)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 8 -->
          <div class="card-body">
            <div class="row detail">
              <div class="col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-dest">
                <h3 class="card-title">
                  {{
                    'COMPANY_REGISTER.COMPANY_REGISTER_USAGE_HISTORY'
                      | translate
                  }}
                </h3>
              </div>
              <div class="col-12 col-md-8 col-xl-8 col-dest1">
                <div class="col-12">
                  <span class="badge badge-info">{{
                    'COMMON.COMMON_REQUIRE_LABEL_1' | translate
                  }}</span>
                  <div
                    class="custom-control custom-radio display"
                    style="padding-left: 10px"
                  >
                  <!-- 利用履歴を使用する -->
                    <div
                      class="custom-control custom-radio display"
                      style="margin-left: 20px"
                      (click)="historyRadio('1')"
                      *ngIf="history_flg == '1'"
                    >
                      <input
                        class="custom-control-input custom-control-input-danger custom-control-input-outline"
                        type="radio"
                        checked
                        id="history1"
                        name="historyRadio"
                        value="{{
                          'COMPANY_REGISTER.COMPANY_REGISTER_USE_USAGE_HISTORY'
                            | translate
                        }}"
                      />
                      <label for="history1" class="custom-control-label">{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_USE_USAGE_HISTORY'
                          | translate
                      }}</label>
                    </div>
                    <div
                      class="custom-control custom-radio display"
                      style="margin-left: 20px"
                      (click)="historyRadio('1')"
                      *ngIf="history_flg != '1'"
                    >
                      <input
                        class="custom-control-input custom-control-input-danger custom-control-input-outline"
                        type="radio"
                        id="history1"
                        name="historyRadio"
                        value="{{
                          'COMPANY_REGISTER.COMPANY_REGISTER_USE_USAGE_HISTORY'
                            | translate
                        }}"
                      />
                      <label for="history1" class="custom-control-label">{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_USE_USAGE_HISTORY'
                          | translate
                      }}</label>
                    </div>
                    <!-- 利用履歴を使用しない -->
                    <div
                      class="custom-control custom-radio display radio-top"
                      style="margin-left: 20px"
                      (click)="historyRadio('0')"
                      *ngIf="history_flg == '0'"
                    >
                      <input
                        class="custom-control-input custom-control-input-danger custom-control-input-outline"
                        type="radio"
                        id="history2"
                        name="historyRadio"
                        checked
                        value="{{
                          'COMPANY_REGISTER.COMPANY_REGISTER_NOT_USE_USAGE_HISTORY'
                            | translate
                        }}"
                      />
                      <label for="history2" class="custom-control-label">{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_NOT_USE_USAGE_HISTORY'
                          | translate
                      }}</label>
                    </div>
                    <div
                      class="custom-control custom-radio display radio-top"
                      style="margin-left: 20px"
                      (click)="historyRadio('0')"
                      *ngIf="history_flg != '0'"
                    >
                      <input
                        class="custom-control-input custom-control-input-danger custom-control-input-outline"
                        type="radio"
                        id="history2"
                        name="historyRadio"
                        value="{{
                          'COMPANY_REGISTER.COMPANY_REGISTER_NOT_USE_USAGE_HISTORY'
                            | translate
                        }}"
                      />
                      <label for="history2" class="custom-control-label">{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_NOT_USE_USAGE_HISTORY'
                          | translate
                      }}</label>
                    </div>
                  </div>
                  <div class="text1">
                    <p style="margin-top: 10px" class="txt-left">
                      {{
                        'COMPANY_REGISTER.COMPANY_REGISTER_USAGE_HISTORY_DESC'
                          | translate
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 9 -->
          <div class="card-body">
            <div class="row detail">
              <div
                class="col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-dest"
                style="margin-top: -15px"
              >
                <h3 class="card-title">
                  {{
                    'COMPANY_REGISTER.COMPANY_REGISTER_FORM_TYPE' | translate
                  }}
                </h3>
              </div>
              <div class="col-12 col-md-8 col-xl-8 col-dest1">
                <div class="col-12" style="margin-top: -44px">
                  <div class="display col-1">
                    <span class="badge badge-info absolute-badge">{{
                      'COMMON.COMMON_REQUIRE_LABEL_1' | translate
                    }}</span>
                  </div>
                  <div
                    class="card-body custom-control display changeType"
                    style="padding-left: 0% !important"
                  >
                    <div class="display col-xl-5 col-md-5 col-6">
                      <a
                        id="type1"
                        type="button"
                        class="btn-mi btn-primary btn-lg text-center btn1"
                        (click)="changeType(1)"
                      >
                        {{
                          'COMPANY_REGISTER.COMPANY_REGISTER_CONTRACT_COMPANY_EMPLOYEE_1'
                            | translate
                        }}<br />{{
                          'COMPANY_REGISTER.COMPANY_REGISTER_CONTRACT_COMPANY_EMPLOYEE_2'
                            | translate
                        }}
                      </a>
                    </div>
                    <div class="custom-control display col-xl-5 col-md-5 col-6">
                      <a
                        id="type2"
                        type="button"
                        class="btn-mi btn-secondary btn-lg text-center btn1"
                        (click)="changeType(2)"
                      >
                        {{
                          'COMPANY_REGISTER.COMPANY_REGISTER_INSURED_PERSON_1'
                            | translate
                        }}<br />{{
                          'COMPANY_REGISTER.COMPANY_REGISTER_INSURED_PERSON_2'
                            | translate
                        }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 保険証(記号) -->
          <div class="card-body" *ngIf = "corporation_form != '3'">
            <div class="row detail">
              <div class="col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-dest">
                <h3 class="card-title">
                  {{ 'COMPANY_REGISTER.COMPANY_HEALTH_CODE' | translate }}
                </h3>
              </div>
              <div class="col-12 col-md-8 col-xl-8 col-dest1">
                <div class="col-12">
                  <span class="badge badge-info" *ngIf="corporation == '1'">{{
                    'COMMON.COMMON_REQUIRE_LABEL_1' | translate
                  }}</span>
                  <span class="badge badge-secondary badge-secondary-1" *ngIf="corporation == '2'">{{
                    'COMMON.COMMON_REQUIRE_LABEL_2' | translate
                  }}</span>
                  <div class="custom-control display col-xl-5 col-md-5 col-10" style="margin-top: -44px">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{
                        'COMPANY_REGISTER.COMPANY_HEALTH_CODE'
                          | translate
                      }}"
                      name="health_code"
                      [(ngModel)]="health_code"
                      maxlength="10"
                    />
                  </div>
                  
                  <div class="text1">
                    <p class="txt-left">
                      ※保険証に記載されている<a class="modaltext" (click)="open(content)">記号情報</a>を記入して下さい。 <br>
                      &#160;&#160;&#160;&#160;記号情報は法人入会時の企業判断に使用されます。
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 会費負担 -->
          <div class="card-body" id="paid_form">
            <div class="row detail">
              <div
                class="col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-dest"
                style="margin-top: -15px">
                <h3 class="card-title">
                  {{
                    'COMPANY_REGISTER.COMPANY_REGISTER_PAID_FORM' | translate
                  }}
                </h3>
              </div>
              <div class="col-12 col-md-8 col-xl-8 col-dest1">
                <div class="col-12" style="margin-top: -44px">
                  <div class="display col-1">
                    <span class="badge badge-info absolute-badge" style="top: -30px;">{{
                      'COMMON.COMMON_REQUIRE_LABEL_1' | translate
                    }}</span>
                  </div>
                  <div
                    class="card-body custom-control display changeType"
                    style="padding-left: 0% !important"
                  >
                    <div class="display col-xl-5 col-md-5 col-6">
                      <a
                        id="burden1"
                        type="button"
                        class="btn-mi btn-primary btn-lg text-center btn-burden"
                        (click)="changeBurden('3')"
                      >
                        {{
                          'COMPANY_REGISTER.COMPANY_REGISTER_PERSONAL_BURDEN'
                            | translate
                        }}
                      </a>
                    </div>
                    <div class="custom-control display col-xl-5 col-md-5 col-6">
                      <a
                        id="burden2"
                        type="button"
                        class="btn-mi btn-secondary btn-lg text-center btn-burden"
                        (click)="changeBurden('1')"
                      >
                        {{
                          'COMPANY_REGISTER.COMPANY_REGISTER_CORPORATE_BURDEN'
                            | translate
                        }}
                      </a>
                    </div>
                  </div>
                  <div class="text1">
                    <p class="txt-left" style="margin-top: -10px;">
                      {{
                        'COMPANY_REGISTER.COMPANY_REGISTER_BURDEN_ABOUT_TEXT1'
                          | translate
                      }} <br>          
                     {{
                        'COMPANY_REGISTER.COMPANY_REGISTER_BURDEN_ABOUT_TEXT2'
                          | translate
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row detail">
              <div class="col-12 col-sm-1 col-md-3 col-lg-2 col-xl-2 col-dest">
                <h3 class="card-title"></h3>
              </div>
              <div class="col-12 col-sm-10 col-md-8 col-lg-8 col-xl-8 col-dest1">
                <div class="col-12">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <h3 class="card-desc pdf-about-text">
                      {{
                        'COMPANY_REGISTER.COMPANY_REGISTER_PDF_DESC' | translate
                      }}
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 10 -->
          <div class="card-body" style="margin-top: -30px">
            <div class="row detail">
              <div class="col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-dest">
                <h3 class="card-title">
                  {{ 'COMPANY_REGISTER.COMPANY_REGISTER_PDF_FILE' | translate }}
                </h3>
              </div>
              <div class="col-12 col-md-8 col-xl-8 col-dest1">
                <div class="col-12">
                  <span class="badge badge-secondary badge-secondary-1">{{
                    'COMMON.COMMON_REQUIRE_LABEL_2' | translate
                  }}</span>
                  <div class="custom-control display">
                    <!-- <h3 class="font2" id="pdffile_name">
                      {{
                        'COMPANY_REGISTER.COMPANY_REGISTER_PDF_FILE_DESC'
                          | translate
                      }}
                    </h3> -->
                    <input
                      type="text"
                      class="form-control"
                      placeholder="{{
                        'COMPANY_REGISTER.COMPANY_REGISTER_PDF_FILE_DESC'
                          | translate
                      }}"
                      name="pdffile_name"
                      [(ngModel)]="pdffile_name"
                      disabled
                    />
                  </div>
                  <div class="custom-control display col-xl-3 col-md-5 col-10">
                    <button
                      class="form-control select-pdf"
                      (click)="selectPDF()"
                      style="width: 95px; font-size: 12px"
                    >
                      {{
                        'COMPANY_REGISTER.COMPANY_REGISTER_PDF_FILE_CHOOSE'
                          | translate
                      }}
                    </button>
                    <input
                      type="file"
                      accept="application/pdf"
                      id="pdffile"
                      (change)="upload($event)"
                      style="display: none"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 10 PDF Button -->
          <!-- <div class="card-body">
            <div class="row detail">
              <div class="col-12 col-sm-3 col-md-3 col-lg-2 col-xl-2 col-dest">
                <h3 class="card-title">
                  {{
                    'COMPANY_REGISTER.COMPANY_REGISTER_CURRENT_PDF_FILE'
                      | translate
                  }}
                </h3>
              </div>
              <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-dest1">
                <div class="col-12">
                  <div
                    class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8"
                    style="padding-left: 50px"
                  >
                    <a class="btn-pdf pdf-text" (click)="downloadPDF()">
                      <i class="far fa-file-alt"></i>
                      {{
                        'COMPANY_REGISTER.COMPANY_REGISTER_CURRENT_PDF_FILE_DISPLAY'
                          | translate
                      }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- buttons -->
         <!-- Button -->
         <div class="text-center form-wd-1">
          <div class="text1 text-center"  style="height: 34px; margin-top: 20px;">
              <p id="error" class="error-text-cls"></p>
          </div>
          <div class="two-btn">
            <div class="display">
              <a (click)="back(1)" class="btn-back">
                {{'COMMON.COMMON_BACK_BUTTON' | translate}}
              </a>
            </div>
            <div class="display">
              <a (click)="input()" class="btn-next">
                {{ 'COMMON.COMMON_NEXT_BUTTON' | translate }}
              </a>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>

  <!-- @@@@@@@@@@@@@@@@@@@@@@@@@ -->
  <!-- -->
  <!-- -->
  <!-- 入力内容を確認してください。-->
  <!-- -->
  <!-- -->
  <!-- @@@@@@@@@@@@@@@@@@@@@@@@@ -->

  <section class="content" id="regConfirm" style="display: none;">
    <div style="position: absolute; top: 0; left: 0; width: 100%">
      <nav
        class="navbar navbar-expand navbar-color navbar-light form-hd-2"
        style="margin-left: 0px; height: 70px; padding-left: 0;"
      >
        <a [routerLink]="['/']">
          <img
            src="assets/img/fit365_logo.svg"
            alt="AdminLTE Logo"
            class="brand-image"
            style="margin-left: 20px;"
        /></a>
      </nav>
    <!-- STEP -->
    <div class="container-fluid">
      <div class="col-md-12 left main-top form-wd-2">
        <div
          class="col-6 col-md-6 col-xl-6 steps step-left"
          style="flex: 0 0 45%; max-width: 45%"
        >
          <li class="done">
            <p>
              {{ 'COMPANY_REGISTER.STEP' | translate }}
              <strong>{{ 'COMPANY_REGISTER.STEP_1' | translate }}</strong>
            </p>
          </li>
        </div>
        <div
          class="col-6 col-md-6 col-xl-6 steps steps-s step-left"
          style="flex: 0 0 45%; max-width: 45%"
        >
          <li class="active">
            <p>
              {{ 'COMPANY_REGISTER.STEP' | translate }}
              <strong>{{ 'COMPANY_REGISTER.STEP_2' | translate }}</strong>
            </p>
          </li>
        </div>
      </div>
    <h3 class="header-bottom form-wd-2" style="margin-left: 5%; margin-right: 5%;">
      {{ 'COMPANY_REGISTER.TITLE_2' | translate }}
    </h3>
    <div class="row">
      <div class="col-md-8 offset-lg-3 offset-md-2 offset-1">
        <!-- 1 -->
        <div class="card-body">
          <div class="row detail1">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
              <h3 class="card-title">
                {{ 'COMPANY_REGISTER.COMPANY_REGISTER_STOCK' | translate }}
              </h3>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-dest2">
              <div class="col-12 col-dest2">
                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
                  <h3 class="card-desc">{{ selectedRadio }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 2 -->
        <div class="card-body">
          <div class="row detail1">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
              <h3 class="card-title">
                {{
                  'COMPANY_REGISTER.COMPANY_REGISTER_COMPANY_NAME' | translate
                }}
              </h3>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-dest2">
              <div class="col-12 col-dest2">
                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
                  <h3 class="card-desc">{{ companyName }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 3 -->
        <div class="card-body">
          <div class="row detail1">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest"></div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-dest2">
              <div class="col-12 col-dest2">
                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8">
                  <h3 style="margin-top: -25px" class="card-desc">
                    {{ companyNameKana }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 4 -->
        <div class="card-body">
          <div class="row detail1">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
              <h3 class="card-title">
                {{ 'COMPANY_REGISTER.COMPANY_REGISTER_ADDRESS' | translate }}
              </h3>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-dest2">
              <div class="col-12 col-dest2">
                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
                  <h3 class="card-desc">
                    {{ postcode }}<br />{{ address_1 }}<br />{{ address_2
                    }}{{ address_3 }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 5 -->
        <div class="card-body">
          <div class="row detail1">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
              <h3 class="card-title">
                {{ 'COMPANY_REGISTER.COMPANY_REGISTER_PHONE' | translate }}
              </h3>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-dest2">
              <div class="col-12 col-dest2">
                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
                  <h3 class="card-desc">{{ telephone }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 業種・業態 -->
        <div class="card-body">
          <div class="row detail1">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
              <h3 class="card-title">
                {{ 'COMPANY_REGISTER.COMPANY_BUSINESS_TYPE' | translate }}
              </h3>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-dest2">
              <div class="col-12 col-dest2">
                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
                  <h3 class="card-desc">{{ businessName}}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 6 -->
        <div class="card-body">
          <div class="row detail1">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
              <h3 class="card-title">
                {{ 'COMPANY_REGISTER.COMPANY_REGISTER_NAME' | translate }}
              </h3>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-dest2">
              <div class="col-12 col-dest2">
                <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 display">
                  <h3 class="card-desc display">{{ lastname }}</h3>
                  <h3 class="card-desc" style="padding-left: 18px">{{ firstname }}</h3>
                </div>
              </div>
              <div class="col-12 col-dest2">
                <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-8 display">
                  <h3 class="card-desc display">{{ lastname_kana }}</h3>
                  <h3 class="card-desc" style="padding-left: 18px">
                    {{ firstname_kana }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 7 -->
        <div class="card-body">
          <div class="row detail1">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
              <h3 class="card-title">
                {{ 'COMPANY_REGISTER.COMPANY_REGISTER_DEPARTMENT' | translate }}
              </h3>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-dest2">
              <div class="col-12 col-dest2">
                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
                  <h3 class="card-desc display">{{ staff_post }}</h3>
                  <h3 class="card-desc" style="padding-left: 18px">{{ staff_position }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 8 -->
        <div class="card-body">
          <div class="row detail1">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
              <h3 class="card-title">
                {{
                  'COMPANY_REGISTER.COMPANY_REGISTER_MEMBER_COUNT' | translate
                }}
              </h3>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-dest2">
              <div class="col-12 col-dest2">
                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
                  <h3 class="card-desc">{{ memberCount }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 9 -->
        <div class="card-body">
          <div class="row detail1">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
              <h3 class="card-title">
                {{
                  'COMPANY_REGISTER.COMPANY_REGISTER_USAGE_HISTORY' | translate
                }}
              </h3>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-dest2">
              <div class="col-12 col-dest2">
                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
                  <h3 class="card-desc">{{ history_flg_radio }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 10 -->
        <div class="card-body">
          <div class="row detail1">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
              <h3 class="card-title">
                {{ 'COMPANY_REGISTER.COMPANY_REGISTER_FORM_TYPE' | translate }}
              </h3>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-dest2">
              <div class="col-12 col-dest2">
                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
                  <h3 class="card-desc">{{ corporation_form }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 保険証(記号) -->
        <div class="card-body" *ngIf = "corporation_form != '3'">
          <div class="row detail1">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
              <h3 class="card-title">
                {{ 'COMPANY_REGISTER.COMPANY_HEALTH_CODE' | translate }}
              </h3>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-dest2">
              <div class="col-12 col-dest2">
                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
                  <h3 class="card-desc">{{ health_code }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 会費負担 -->
        <div class="card-body" *ngIf="corporation == '1'">
          <div class="row detail1">
            <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 col-dest">
              <h3 class="card-title">
                {{ 'COMPANY_REGISTER.COMPANY_REGISTER_PAID_FORM' | translate }}
              </h3>
            </div>
            <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-8 col-dest2">
              <div class="col-12 col-dest2">
                <div class="col-12 col-sm-8 col-md-8 col-lg-8 col-xl-12">
                  <h3 class="card-desc">{{ burden_form }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TEXT -->
    <div class="text-center con-text">
      <p>
        {{ 'COMPANY_REGISTER.COMPANY_REGISTER_CONFIRM_TITLE' | translate }}
      </p>
    </div>
    <div class="text-center con-text">
      <p class="responsive">
        {{ 'COMPANY_REGISTER.COMPANY_REGISTER_CONFIRM_TXT_1' | translate
        }}<br />{{
          'COMPANY_REGISTER.COMPANY_REGISTER_CONFIRM_TXT_2' | translate
        }}
      </p>
    </div>
    <!-- Buttons -->
    <div class="text-center form-wd-2">
      <div class="two-btn">
        <div class="display">
          <a (click)="back(2)" class="btn-back">
            {{'COMMON.COMMON_BACK_BUTTON' | translate}}
          </a>
        </div>
        <div class="display">
          <a (click)="companyUpdate()" class="btn-next">
            {{ 'COMPANY_REGISTER.COMPANY_REGISTER_FINISH_BUTTON' | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
  </section>
  <ng-template #content let-c="close" let-d="dismiss" >
    <div class="modal-body">
      <img src={{IMAGE_URL}} alt="">
    </div>
  </ng-template>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#E10800 " type = "square-jelly-box" [fullScreen] = "true">
  <p style="color: #E10800 " > {{ 'COMMON.LOADING' | translate }} </p>>
</ngx-spinner>