import { Injectable } from "@angular/core";
// import { USER_POOL_ID, CLIENT_ID } from '../../environments/environment';

@Injectable()
export class DataProvider {
    public menuPageTitle: string = "home";
    public canLogin: boolean = false;
    public loginError: string = "";
    public loggedUsername: string = "";
    public loggedEmail: string = "";
    public loggedPassword: string = "";
    public globalLoading: boolean = false;
    // public poolData = {
    //     UserPoolId: USER_POOL_ID,
    //     ClientId: CLIENT_ID
    // };
    constructor() {
      
    }
}