import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment, IMAGE_URL } from 'src/environments/environment';
import {Title} from "@angular/platform-browser";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-company-register-edit',
  templateUrl: './company-register-edit.component.html',
  styleUrls: ['./company-register-edit.component.scss'],
})
export class CompanyEditComponent implements OnInit, OnDestroy {
  // public registerForm: FormGroup;
  public Company_Data = [];
  public uuid;
  public pattern;
  public corporate_status;
  public selectedRadio; //corporate_status

  public companyName;
  public companyNameKana;

  public postcode;
  public address_1;
  public address_2;
  public address_3;
  public telephone;
  public notKana = false;
  public notFirstnameKana = false;
  public notLastnameKana = false;

  public lastname;
  public firstname;
  public lastname_kana;
  public firstname_kana;

  public staff_post;
  public staff_position;

  public judge_state;
  public memberCount;
  public history_flg;
  public history_flg_radio;

  public corporation_form;
  public corporation = '1';
  public today;
  public app_date;
  public app_time;

  public error_text = '';
  public mail_send_flg;
  public crypto_uuid;
  public PDF_REG;
  public EDIT_REG;
  public pdffile_name;
  public call_flg = '2';
  public burden = '1';
  public burden_form;
  public business_type = '';
  public business_code = '';
  public business_name = '';
  public businessName = '';
  public BusinessType = [];
  public insurance_card = '';
  public health_code = '';
  public paid_form;
  public fees;

  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public rest: CallapiService,
    protected datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private titleService:Title,
    private modalService: NgbModal
  ) {
    this.titleService.setTitle("JOYFIT 法人契約申込み・再審査フォーム");
    this.translate
      .get('COMPANY_REGISTER.COMPANY_REGISTER_PREV_STOCK')
      .subscribe((res) => (this.selectedRadio = res));
    this.translate
      .get('COMPANY_REGISTER.COMPANY_REGISTER_USE_USAGE_HISTORY')
      .subscribe((res) => (this.history_flg_radio = res));
    this.translate
      .get('COMPANY_REGISTER.COMPANY_REGISTER_CONTRACT_COMPANY_EMPLOYEE_1')
      .subscribe((res) => {
        this.corporation_form = res;
        this.translate
          .get('COMPANY_REGISTER.COMPANY_REGISTER_CONTRACT_COMPANY_EMPLOYEE_2')
          .subscribe(
            (res) => (this.corporation_form = this.corporation_form + res)
          );
      });
    this.translate
      .get('COMPANY_REGISTER.COMPANY_REGISTER_PERSONAL_BURDEN')
      .subscribe((res) => (this.burden_form = res));
    this.BusinessType = [
      {code: "01", name: "農業・林業"},
      {code: "02", name: "漁業"},
      {code: "03", name: "鉱業，採石業，砂利，採取業"},
      {code: "04", name: "建設業"},
      {code: "05", name: "製造業"},
      {code: "06", name: "電気・ガス・熱供給・水道業"},
      {code: "07", name: "情報通信業"},
      {code: "08", name: "運輸業，郵便業"},
      {code: "09", name: "卸売業，小売業"},
      {code: "10", name: "金融業，保険業"},
      {code: "11", name: "不動産業，物品賃貸業"},
      {code: "12", name: "学術研究，専門・技術サービス業"},
      {code: "13", name: "宿泊業，飲食サービス業"},
      {code: "14", name: "生活関連サービス業，娯楽業"},
      {code: "15", name: "教育，学習支援業"},
      {code: "16", name: "医療，福祉"},
      {code: "17", name: "複合サービス事業"},
      {code: "18", name: "サービス業"},
      {code: "19", name: "公務"},
      {code: "20", name: "分類不能の産業"},
    ];
  }

  public logoUrl;
  public IMAGE_URL;
  ngOnInit() {
    this.logoUrl = environment.production ? 'assets/img/fit365_logo.svg' : 'assets/img/fit365_logo_test_mode.svg';
    this.IMAGE_URL = IMAGE_URL;
    this.renderer.addClass(document.querySelector('app-root'), 'company-page');
    this.crypto_uuid = this.route.snapshot.paramMap.get('id');
    this.pattern = this.route.snapshot.paramMap.get('cpn');
    this.rest.Check_Applied(this.crypto_uuid, this.pattern, this.call_flg).subscribe(
      (res)=>{
      },
      (err)=>{
        this.router.navigate(['/applied-error',{ code: err.error.error.code },]);
      }
    )
    if (this.crypto_uuid) {
      if (this.pattern == '0') {
        // B-C-D-E-A
        var uuid_splitted = this.crypto_uuid.split('-', 5);
        var uuid_B = uuid_splitted[0];
        var uuid_C = uuid_splitted[1];
        var uuid_D = uuid_splitted[2];
        var uuid_E = uuid_splitted[3];
        var uuid_A = uuid_splitted[4];
        this.uuid =
          uuid_A + '-' + uuid_B + '-' + uuid_C + '-' + uuid_D + '-' + uuid_E;
      } else if (this.pattern == '1') {
        // C-D-E-A-B
        var uuid_splitted = this.crypto_uuid.split('-', 5);
        var uuid_C = uuid_splitted[0];
        var uuid_D = uuid_splitted[1];
        var uuid_E = uuid_splitted[2];
        var uuid_A = uuid_splitted[3];
        var uuid_B = uuid_splitted[4];
        this.uuid =
          uuid_A + '-' + uuid_B + '-' + uuid_C + '-' + uuid_D + '-' + uuid_E;
      } else if (this.pattern == '2') {
        // D-E-A-B-C
        var uuid_splitted = this.crypto_uuid.split('-', 5);
        var uuid_D = uuid_splitted[0];
        var uuid_E = uuid_splitted[1];
        var uuid_A = uuid_splitted[2];
        var uuid_B = uuid_splitted[3];
        var uuid_C = uuid_splitted[4];
        this.uuid =
          uuid_A + '-' + uuid_B + '-' + uuid_C + '-' + uuid_D + '-' + uuid_E;
      } else if (this.pattern == '3') {
        // E-A-B-C-D
        var uuid_splitted = this.crypto_uuid.split('-', 5);
        var uuid_E = uuid_splitted[0];
        var uuid_A = uuid_splitted[1];
        var uuid_B = uuid_splitted[2];
        var uuid_C = uuid_splitted[3];
        var uuid_D = uuid_splitted[4];
        this.uuid =
          uuid_A + '-' + uuid_B + '-' + uuid_C + '-' + uuid_D + '-' + uuid_E;
      }
    }
    this.spinner.show();
    this.rest.Get_ApplicationsId(this.uuid, this.uuid).subscribe(
      (res) => {
        this.companyName = res.data.company_name;
        this.companyNameKana = res.data.company_name_kana;
        this.postcode = res.data.postcode;
        this.address_1 = res.data.address_1;
        this.address_2 = res.data.address_2;
        this.address_3 = res.data.address_3;
        this.telephone = res.data.tel;
        this.firstname = res.data.staff_first_name;
        this.lastname = res.data.staff_last_name;
        this.firstname_kana = res.data.staff_first_name_kana;
        this.lastname_kana = res.data.staff_last_name_kana;
        this.staff_post = res.data.staff_post;
        this.staff_position = res.data.staff_position;
        this.memberCount = res.data.users;
        this.corporate_status = res.data.corporate_status;
        this.history_flg = res.data.history_flg;
        this.business_type = res.data.business_type;
        this.health_code = res.data.health_code;
        this.judge_state = res.data.judge_state;
        this.corporation_form = res.data.corporation_form;
        this.fees = res.data.fees1;
        if (this.corporation_form == 1) {
          this.translate
            .get(
              'COMPANY_REGISTER.COMPANY_REGISTER_CONTRACT_COMPANY_EMPLOYEE_1'
            )
            .subscribe((res) => {
              this.corporation_form = res;
              this.translate
                .get(
                  'COMPANY_REGISTER.COMPANY_REGISTER_CONTRACT_COMPANY_EMPLOYEE_2'
                )
                .subscribe(
                  (res) => (this.corporation_form = this.corporation_form + res)
                );
            });
          document.getElementById('paid_form').style.display = 'block';
          this.paid_form = res.data.paid_form;
          this.changeBurden(this.paid_form);
          document.getElementById('type1').classList.remove('btn-secondary');
          document.getElementById('type1').classList.add('btn-primary');
          document.getElementById('type2').classList.remove('btn-primary');
          document.getElementById('type2').classList.add('btn-secondary');
          this.corporation = '1';
        } else {
          this.translate
            .get('COMPANY_REGISTER.COMPANY_REGISTER_INSURED_PERSON_1')
            .subscribe((res) => {
              this.corporation_form = res;
              this.translate
                .get('COMPANY_REGISTER.COMPANY_REGISTER_INSURED_PERSON_2')
                .subscribe(
                  (res) => (this.corporation_form = this.corporation_form + res)
                );
            });
          document.getElementById('type2').classList.remove('btn-secondary');
          document.getElementById('type2').classList.add('btn-primary');
          document.getElementById('type1').classList.remove('btn-primary');
          document.getElementById('type1').classList.add('btn-secondary');
          this.corporation = '2';
          this.paid_form = '3';
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
    if (this.postcode) {
      this.rest.ZipCode(this.postcode).subscribe((res) => {
        if (res.results) {
          this.address_1 =
            res.results[0].address1 +
            res.results[0].address2 +
            res.results[0].address3;
        } else if (res.results == null) {
          this.address_1 = '';
          this.translate
            .get(res.message)
            .subscribe(
              (res) => (document.getElementById('error').innerHTML = res)
            );
          document.getElementById('error').style.display = 'block';
        }
      });
    }
  }
  changeBusiness(){
    for(var i = 0; i< this.BusinessType.length; i++){
      if(this.BusinessType[i].code == this.business_type){
        this.businessName = this.BusinessType[i].name;
      }
    }
  }
  onKey(data) {
    this.rest.ZipCode(data).subscribe((res) => {
      if (res.results) {
        this.address_1 =
          res.results[0].address1 +
          res.results[0].address2 +
          res.results[0].address3;
      } else if (res.results == null) {
        this.address_1 = '';
        this.translate
          .get(res.message)
          .subscribe(
            (res) => (document.getElementById('error').innerHTML = res)
          );
        document.getElementById('error').style.display = 'block';
      }
    });
  }

  back(id) {
    if (id == 1) {
      this.router.navigate([
        '/request',
        { id: this.crypto_uuid, cpn: this.pattern },
      ]);
    } else {
      document.getElementById('regConfirm').style.display = 'none';
      document.getElementById('regCompany').style.display = 'block';
    }
  }

  selectPDF() {
    const input: HTMLElement = document.getElementById('pdffile');
    input.click();
  }

  upload(event: Event) {
    const self: CompanyEditComponent = this;
    const reader: FileReader = new FileReader();
    reader.onload = function () {
      var pdf_string = reader.result.toString();
      var pdf_binary = pdf_string.split('data:application/pdf;base64,');
      window.IMAGE_RESULT = pdf_binary[1];
    };
    const element: HTMLInputElement = <HTMLInputElement>event.srcElement;
    if (element.files.length > 0) {
      reader.readAsDataURL(element.files[0]);
      this.pdffile_name = element.files[0].name;
    }
    element.value = '';
  }

  downloadPDF() {
    var user_uuid = ''
    const body = {
      data: {
        user_uuid: user_uuid,
        uuid: this.uuid,
      },
    };
    this.rest.Download_Regcertificates(user_uuid, this.uuid).subscribe(
      (res) => {
        if (res.code == 'S000001') {
          window.open(res.data.s3url, '_blank', '');
        }
      },
      (err) => {
      }
    );
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'company-page'
    );
  }

  selectedRadios(id) {
    if (id == 1) {
      this.translate
        .get('COMPANY_REGISTER.COMPANY_REGISTER_PREV_STOCK')
        .subscribe((res) => (this.selectedRadio = res));
      this.corporate_status = '1';
    } else if (id == 2) {
      this.translate
        .get('COMPANY_REGISTER.COMPANY_REGISTER_NEXT_STOCK')
        .subscribe((res) => (this.selectedRadio = res));
      this.corporate_status = '2';
    } else {
      this.translate
        .get('COMPANY_REGISTER.COMPANY_REGISTER_OTHER_STOCK')
        .subscribe((res) => (this.selectedRadio = res));
      this.corporate_status = '3';
    }
  }

  historyRadio(flg) {
    if (flg == 1) {
      this.translate
        .get('COMPANY_REGISTER.COMPANY_REGISTER_USE_USAGE_HISTORY')
        .subscribe((res) => (this.history_flg_radio = res));
      this.history_flg = '1';
    } else {
      this.translate
        .get('COMPANY_REGISTER.COMPANY_REGISTER_NOT_USE_USAGE_HISTORY')
        .subscribe((res) => (this.history_flg_radio = res));
      this.history_flg = '0';
    }
  }

  changeType(type) {
    if (type == 1) {
      this.translate
        .get('COMPANY_REGISTER.COMPANY_REGISTER_CONTRACT_COMPANY_EMPLOYEE_1')
        .subscribe((res) => {
          this.corporation_form = res;
          this.translate
            .get(
              'COMPANY_REGISTER.COMPANY_REGISTER_CONTRACT_COMPANY_EMPLOYEE_2'
            )
            .subscribe(
              (res) => (this.corporation_form = this.corporation_form + res)
            );
        });
      document.getElementById('paid_form').style.display = 'block';
      document.getElementById('type1').classList.remove('btn-secondary');
      document.getElementById('type1').classList.add('btn-primary');
      document.getElementById('type2').classList.remove('btn-primary');
      document.getElementById('type2').classList.add('btn-secondary');
      this.corporation = '1';
    } else {
      this.translate
        .get('COMPANY_REGISTER.COMPANY_REGISTER_INSURED_PERSON_1')
        .subscribe((res) => {
          this.corporation_form = res;
          this.translate
            .get('COMPANY_REGISTER.COMPANY_REGISTER_INSURED_PERSON_2')
            .subscribe(
              (res) => (this.corporation_form = this.corporation_form + res)
            );
        });
      document.getElementById('type2').classList.remove('btn-secondary');
      document.getElementById('type2').classList.add('btn-primary');
      document.getElementById('type1').classList.remove('btn-primary');
      document.getElementById('type1').classList.add('btn-secondary');
      document.getElementById('paid_form').style.display = 'none';
      this.corporation = '2';
      this.paid_form = '3';
    }
  }

  open(content) {
		this.modalService.open(content);
	}
  
  changeBurden(type) {
    this.paid_form = type;
    if (type == "3") {
      this.translate
        .get('COMPANY_REGISTER.COMPANY_REGISTER_PERSONAL_BURDEN')
        .subscribe((res) => {
          this.burden_form = res;
        });
      document.getElementById('burden1').classList.remove('btn-secondary');
      document.getElementById('burden1').classList.add('btn-primary');
      document.getElementById('burden2').classList.remove('btn-primary');
      document.getElementById('burden2').classList.add('btn-secondary');
    } else {
      this.translate
        .get('COMPANY_REGISTER.COMPANY_REGISTER_CORPORATE_BURDEN')
        .subscribe((res) => {
          this.burden_form = res;
        });
      document.getElementById('burden2').classList.remove('btn-secondary');
      document.getElementById('burden2').classList.add('btn-primary');
      document.getElementById('burden1').classList.remove('btn-primary');
      document.getElementById('burden1').classList.add('btn-secondary');
      this.paid_form = '1';
    }
  }

  change() {
    document.getElementById('error').style.display = 'none';
  }

  dataValidation() {
    let err = false;
    let errTxt = '';
    let Number = /^-?\d*$/;
    if(this.corporation == '1'){
      if (this.health_code == null || this.health_code == '') {
        err = true;
        this.translate
          .get('COMPANY_REGISTER_VALIDATION.ERROR_INSURANCE_CARD')
          .subscribe((res) => (errTxt = res));
      }
    }
    if (this.health_code != "" && !Number.test(this.health_code)) {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_INSURANCE_CARD_NO_NUMBER')
        .subscribe((res) => (errTxt = res));
    }
    if (this.memberCount == null || this.memberCount == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_MEMBER_COUNT')
        .subscribe((res) => (errTxt = res));
    } else if (this.memberCount != "" && !Number.test(this.memberCount)) {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_MEMBER_COUNT_NO_NUMBER')
        .subscribe((res) => (errTxt = res));
    }
    if (this.notFirstnameKana && this.firstname_kana !== null && this.firstname_kana !== '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_NOT_KITAGANA_FIRSTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.firstname_kana == null || this.firstname_kana == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_KITAGANA_FIRSTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.notLastnameKana && this.lastname_kana !== null && this.lastname_kana !== '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_NOT_KITAGANA_LASTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.lastname_kana == null || this.lastname_kana == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_KITAGANA_LASTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.firstname == null || this.firstname == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_FIRSTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.lastname == null || this.lastname == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_LASTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.business_type == null || this.business_type == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_BUSINESS_TYPE')
        .subscribe((res) => (errTxt = res));
    }
    if (this.telephone == null || this.telephone == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_TELEPHONE')
        .subscribe((res) => (errTxt = res));
    } else if (this.telephone != "" && !Number.test(this.telephone)) {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_TELEPHONE_NO_NUMBER')
        .subscribe((res) => (errTxt = res));
    }
    // if (this.address_3 == null || this.address_3 == '') {
    //   err = true;
    //   this.translate
    //     .get('COMPANY_REGISTER_VALIDATION.ERROR_ADDRESS_3')
    //     .subscribe((res) => (errTxt = res));
    // }
    if (this.address_2 == null || this.address_2 == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_ADDRESS_2')
        .subscribe((res) => (errTxt = res));
    }
    if (this.address_1 == null || this.address_1 == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_ADDRESS_1')
        .subscribe((res) => (errTxt = res));
    }
    if (this.postcode == null || this.postcode == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_POSTCODE')
        .subscribe((res) => (errTxt = res));
    } else if (this.postcode != "" && !Number.test(this.postcode)) {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_POSTCODE_NO_NUMBER')
        .subscribe((res) => (errTxt = res));
    }
    if (this.notKana === true && this.companyNameKana !== null && this.companyNameKana !== '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_COMPANY_NAME_NOT_KANA')
        .subscribe((res) => (errTxt = res));
    }
    if (this.companyNameKana == null || this.companyNameKana == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_COMPANY_NAME_KANA')
        .subscribe((res) => (errTxt = res));
    }
    if (this.companyName == null || this.companyName == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_COMPANY_NAME')
        .subscribe((res) => (errTxt = res));
    }
    if (err) {
      document.getElementById('error').innerHTML = errTxt;
      document.getElementById('error').style.display = 'block';
      return false;
    }
    return true;
  }

  input() {
    this.mail_send_flg = '1';
    if (this.dataValidation() == false) {
      return true;
    } else {
      this.business_code = this.business_type.split(':')[0];
      this.business_name = this.business_type.split(':')[1];
      document.getElementById('regConfirm').style.display = 'block';
      document.getElementById('regCompany').style.display = 'none';
    }
  }
  companyUpdate() {
    this.mail_send_flg = '1';
    this.spinner.show();
    this.rest.Update_Applications(
        this.crypto_uuid,
        this.pattern,
        this.corporate_status,
        this.companyName,
        this.companyNameKana,
        this.postcode,
        this.address_1,
        this.address_2,
        this.address_3,
        this.telephone,
        this.lastname,
        this.firstname,
        this.lastname_kana,
        this.firstname_kana,
        this.staff_post,
        this.staff_position,
        this.judge_state,
        this.corporation,
        this.memberCount.toString(),
        this.history_flg,
        this.mail_send_flg,
        this.business_type,
        this.health_code,
        this.paid_form,
        this.fees,
      ).subscribe(
        (res) => {
          this.EDIT_REG = res.code;
          if (this.EDIT_REG == 'S000001') {
            // Upload PDF
            if (window.IMAGE_RESULT) {
              this.rest.Upload_Regcertificates(this.uuid, window.IMAGE_RESULT).subscribe(
                (res) => {
                  this.spinner.hide();
                  this.router.navigate(['/edit-confirm']);
                },
                (err) => {
                  if (err.error.error) {
                    this.spinner.hide();
                    this.router.navigate(['/applied-error', { code: err.error.error.code }]);
                  } else {
                    this.spinner.hide();
                    this.router.navigate(['/applied-error', { code: 'ERR_PDF' }]);
                  }
                }
              );
            } else {
              this.spinner.hide();
              this.router.navigate(['/edit-confirm']);
            }
          }
        },
        (err) => {
          this.spinner.hide();
          this.router.navigate(['/applied-error',{ code: err.error.error.code },]);
        }
      );
  }

  onKeyKana(katakana) {
    if (!this.isKana(katakana) || katakana.includes('・')) {
      // this.companyNameKana = '';
      this.notKana = true;
    } else {
      this.notKana = false;
    }
  }

  onKeyLastNameKana(katakana) {
    if (!this.isKana(katakana) || katakana.includes('・')) {
      this.notLastnameKana = true;
    } else {
      this.notLastnameKana = false;
    }
  }


  onKeyNameKana(katakana) {
    if (!this.isKana(katakana) || katakana.includes('・')) {
      this.notFirstnameKana = true;
    } else {
      this.notFirstnameKana = false;
    }
  }


  isKana(ch) {
    let k = 0;
    for(let i = 0; i < ch.length; i++) {
      if(ch[i] >= "\u30a0" && ch[i] <= "\u30ff") {
        k++;
      }
    }
    if(k == ch.length) {
      return true;
    }
    return false;
  }
}
