import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-edit-warning',
  templateUrl: './edit-warning.component.html',
  styleUrls: ['./edit-warning.component.scss'],
})
export class EditWarningComponent implements OnInit, OnDestroy {
  public changePasswordForm: FormGroup;
  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    public translate: TranslateService,
    private titleService:Title,
  ) {
    this.titleService.setTitle("JOYFIT 法人契約申込み・再審査フォーム");
  }

  public logoUrl;
  ngOnInit() {
    this.logoUrl = environment.production ? 'assets/img/fit365_logo.svg' : 'assets/img/fit365_logo_test_mode.svg';
    this.renderer.addClass(document.querySelector('app-root'), 'company-page');
    this.changePasswordForm = new FormGroup({
      password: new FormControl(null, Validators.required),
      password2: new FormControl(null, Validators.required),
      retypePassword: new FormControl(null, Validators.required),
    });
  }

  changePassword() {
    if (this.changePasswordForm.valid) {
      this.appService.changePassword();
    } else {
      this.toastr.error('Please insert the password!', 'Error!');
    }
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'company-page'
    );
  }
}
