<div class="centered">
  <div class="center">
    <p class="errortext">予期せぬエラーが発生しました。インターネット接続状態を確認し、ホームボタンを押して下さい。 <br>
      表示がおかしい場合はCTRL+F5を押してブラウザの更新を行ってください。</p>
    <div class="display centerbtn" style="margin-top: 6px">
      <a (click)="reload()" class="btn-next">
        ホーム
      </a>
    </div>
  </div>
</div>
