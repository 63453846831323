import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CompanyRegisterComponent } from './pages/company-register/company-register.component';
import { EmailRegisterComponent } from './views/email-register/email-register.component';
import { AuthGuard } from './utils/guards/auth.guard';
import { NonAuthGuard } from './utils/guards/non-auth.guard';
import { ConfirmComponent } from './pages/confirm/confirm.component';
import { Requests } from './views/request/request.component';
import { CompanyEditComponent } from './pages/company-register-edit/company-register-edit.component';
import { EditConfirmComponent } from './pages/edit-confirm/edit-confirm.component';
import { EditWarningComponent } from './pages/edit-warning/edit-warning.component';
import { CommonErrorComponent } from './pages/common-error/common-error.component';
import {AppliedErrComponent} from './pages/applied-error/applied-error.component'
import { GuidanceComponent } from './pages/guidance/guidance.component';
import { ErrorNetworkComponent } from './pages/networkError/error.component';

const routes: Routes = [
  {
    path: '',
    component: EmailRegisterComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        component: EmailRegisterComponent,
        canActivate: [NonAuthGuard],
      },
    ],
  },
  {
    path: 'request',
    component: Requests,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'edit-confirm',
    component: EditConfirmComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'guidance',
    component: GuidanceComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'confirm',
    component: ConfirmComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'company-register',
    component: CompanyRegisterComponent,
  },
  {
    path: 'company-register-edit',
    component: CompanyEditComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'applied-error',
    component: AppliedErrComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'edit-warning',
    component: EditWarningComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'common-error',
    component: CommonErrorComponent,
    canActivate: [NonAuthGuard],
  },
  {
    path: 'network-error',
    component: ErrorNetworkComponent,
    canActivate: [NonAuthGuard],
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
