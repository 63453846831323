import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-email-register',
  templateUrl: './email-register.component.html',
  styleUrls: ['./email-register.component.scss'],
})
export class EmailRegisterComponent implements OnInit, OnDestroy {
  public registerForm: FormGroup;
  public email;
  private url;
  private AcquiredCompanyCode;
  public error_code = '';
  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    private titleService:Title,

  ) {
    this.url = window.location.href;
    this.titleService.setTitle("JOYFIT 法人契約申込み・再審査フォーム");
  }
  public logoUrl;
  ngOnInit() {
    this.renderer.addClass(document.querySelector('app-root'), 'company-page');
    
    this.logoUrl = environment.production ? 'assets/img/fit365_logo.svg' : 'assets/img/fit365_logo_test_mode.svg';
    this.registerForm = new FormGroup({
      email: new FormControl(null, Validators.required),
    });
    this.route.queryParams.subscribe((params) => {
      if (params == null || params == undefined) {
        this.router.navigate(['/email-register']);
      }
      this.AcquiredCompanyCode = params['acquired_code']; 
    });
    if (
      this.AcquiredCompanyCode == null ||
      this.AcquiredCompanyCode == undefined ||
      this.AcquiredCompanyCode == ''
    ) {
      this.AcquiredCompanyCode = 'HC01';
    }
    this.spinner.show();
    this.rest.Check_AcquiredCode(this.AcquiredCompanyCode).subscribe(
      (res) => {
        this.spinner.hide();
      },
      (err) => {
        console.log(err);
        if (err.status != 200) {
          this.router.navigate(['/common-error', { status: err.status }]);
        }
      }
    );
  }

  warning() {
    this.router.navigate(['/applied-error']);
  }

  change() {
    document.getElementById('error').style.display = 'none';
  }

  register() {
    if (this.registerForm.valid) {
      if (this.email == 'admin') {
        this.warning();
      } else {
        if (!this.validateEmail(this.email)) {
          this.translate
            .get('EMAIL_REGISTER.EMAIL_REGISTER_ERROR_2')
            .subscribe(
              (res) => (document.getElementById('errMsg').innerHTML = res)
            );
          document.getElementById('error').style.display = 'block';
          return;
        }
        this.spinner.show();
        this.rest
          .Create_Sendmail(this.email, this.AcquiredCompanyCode)
          .subscribe(
            (res) => {
              if(res.code == 'S000001'){
                document.getElementById('first').style.display = 'none'
                document.getElementById('second').style.display = 'block'
                this.email = '';
                this.spinner.hide();
              }
            },
            (err) => {
              console.log(err);
              this.error_code = err.error.error.code;
              if (this.error_code == 'EA00101') {
                this.router.navigate(['/applied-error']);
              } else if(this.error_code != 'EA00101') {
                document.getElementById('error').style.display = 'block';
                this.translate.get('ERROR.' + this.error_code).subscribe((res) => (document.getElementById('errMsg').innerHTML = res));
                this.spinner.hide();
              }
              this.spinner.hide();
            }
          );
      }
    } else {
      // this.toastr.error('Please insert the email!', 'Error!');
      this.translate
        .get('EMAIL_REGISTER.EMAIL_REGISTER_ERROR_1')
        .subscribe(
          (res) => (document.getElementById('errMsg').innerHTML = res)
        );
      document.getElementById('error').style.display = 'block';
    }
  }

  validateEmail(mail) {
    var reg = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    return reg.test(mail);
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'company-page'
    );
  }
}
