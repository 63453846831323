<div class="cont">
  <div class="pic">
    <img src="assets/img/guidance.png"/>
    <!-- <h3 class="tit-text">
      {{companyName}} <br> {{'GUIDANCE.TEXT4'| translate}}
    </h3> -->
    <canvas id="canvas" class="canvas"></canvas>
    <div>
      <canvas id="canvas3" class="canvas3"></canvas>
    </div>
  </div>
  <div class="pic">
    <img src="assets/img/guidance1.png"/>
    <!-- <div class="tit-text1">
      <h3>
        {{'GUIDANCE.TEXT1'| translate}} <br>
        {{'GUIDANCE.TEXT2'| translate}}<br>
        <span class="text1">{{'GUIDANCE.TEXT3'| translate}}</span>
      </h3>
    </div> -->
    <!-- <canvas id="canvas1" class="canvas1"></canvas> -->

    <div class="tit-text2">
      <canvas id="canvas2" class="canvas2"></canvas>
    </div>

  </div>

</div>