// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
};

export const API_URL = 'https://78wlw0hx5f.execute-api.ap-northeast-2.amazonaws.com/test';
export const IMAGE_URL = 'https://test-corporation.joyfit-service.jp/image/health_insurance_card_sample.png';
