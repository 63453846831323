import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { DatePipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment, IMAGE_URL } from 'src/environments/environment';
import {Title} from "@angular/platform-browser";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import {$} from '@'

@Component({
  selector: 'app-company-register',
  templateUrl: './company-register.component.html',
  styleUrls: ['./company-register.component.scss'],
})
export class CompanyRegisterComponent implements OnInit, OnDestroy {
  // public registerForm: FormGroup;
  public params;
  public crypto_uuid;
  public uuid;
  public user_uuid;
  public pattern;
  private AcquiredCompanyCode;
  public corporate_status = '1';
  public selectedRadio; //corporate_status
  public secondError;
  pdf_upload_count: number = 0;
  public companyName;
  public companyNameKana;
  public notKana = false;
  public notFirstnameKana = false;
  public notLastnameKana = false;
  public postcode;
  public address_1;
  public address_2;
  public address_3;
  public telephone;

  public lastname;
  public firstname;
  public lastname_kana;
  public firstname_kana;

  public staff_post;
  public staff_position;

  public judge_state;
  public memberCount;
  public history_flg = '1';
  public history_flg_radio;

  public corporation_form;
  public corporation = '1';
  public today;
  public app_date;
  public app_time;

  public error_text = '';
  public pdf_binary;
  public COMPANY_REG;
  public PDF_REG;
  public pdffile_name;
  public call_flg = '1';
  public burden = '1';
  public burden_form;
  public business_type = '';
  public business_code = '';
  public business_name = '';
  public BusinessType = [];
  public insurance_card = '';
  public health_code = '';
  public paid_form = '3';
  
  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    public rest: CallapiService,
    protected datepipe: DatePipe,
    private spinner: NgxSpinnerService,
    private titleService:Title,
    private modalService: NgbModal
  ) {
    this.titleService.setTitle("JOYFIT 法人契約申込み・再審査フォーム");
    this.translate
      .get('COMPANY_REGISTER.COMPANY_REGISTER_PREV_STOCK')
      .subscribe((res) => (this.selectedRadio = res));
    this.translate
      .get('COMPANY_REGISTER.COMPANY_REGISTER_USE_USAGE_HISTORY')
      .subscribe((res) => (this.history_flg_radio = res));
    this.translate
      .get('COMPANY_REGISTER.COMPANY_REGISTER_CONTRACT_COMPANY_EMPLOYEE_1')
      .subscribe((res) => {
        this.corporation_form = res;
        this.translate
          .get('COMPANY_REGISTER.COMPANY_REGISTER_CONTRACT_COMPANY_EMPLOYEE_2')
          .subscribe(
            (res) => (this.corporation_form = this.corporation_form + res)
          );
      });
    this.translate
      .get('COMPANY_REGISTER.COMPANY_REGISTER_PERSONAL_BURDEN')
      .subscribe((res) => (this.burden_form = res));
    this.BusinessType = [
      {code: "01", name: "農業・林業"},
      {code: "02", name: "漁業"},
      {code: "03", name: "鉱業，採石業，砂利，採取業"},
      {code: "04", name: "建設業"},
      {code: "05", name: "製造業"},
      {code: "06", name: "電気・ガス・熱供給・水道業"},
      {code: "07", name: "情報通信業"},
      {code: "08", name: "運輸業，郵便業"},
      {code: "09", name: "卸売業，小売業"},
      {code: "10", name: "金融業，保険業"},
      {code: "11", name: "不動産業，物品賃貸業"},
      {code: "12", name: "学術研究，専門・技術サービス業"},
      {code: "13", name: "宿泊業，飲食サービス業"},
      {code: "14", name: "生活関連サービス業，娯楽業"},
      {code: "15", name: "教育，学習支援業"},
      {code: "16", name: "医療，福祉"},
      {code: "17", name: "複合サービス事業"},
      {code: "18", name: "サービス業"},
      {code: "19", name: "公務"},
      {code: "20", name: "分類不能の産業"},
    ];
  }
  public logoUrl;
  public IMAGE_URL;

  ngOnInit() {
    this.logoUrl = environment.production ? 'assets/img/fit365_logo.svg' : 'assets/img/fit365_logo_test_mode.svg';
    this.IMAGE_URL = IMAGE_URL;
    this.renderer.addClass(document.querySelector('app-root'), 'company-page');
    this.route.queryParams.subscribe((params) => {
      if (params == null || params == undefined) {
        this.router.navigate(['/company-register']);
      }
      this.crypto_uuid = params['id'];
      this.pattern = params['cpn'];
    });
    this.spinner.hide();
    this.rest.Check_Applied(this.crypto_uuid, this.pattern, this.call_flg).subscribe(
      (res)=>{
      },
      (err)=>{
        this.router.navigate(['/applied-error',{ code: err.error.error.code },]);
      }
    )
    if (this.crypto_uuid) {
      if (this.pattern == '0') {
        // B-C-D-E-A
        var uuid_splitted = this.crypto_uuid.split('-', 5);
        var uuid_B = uuid_splitted[0];
        var uuid_C = uuid_splitted[1];
        var uuid_D = uuid_splitted[2];
        var uuid_E = uuid_splitted[3];
        var uuid_A = uuid_splitted[4];
        this.uuid =
          uuid_A + '-' + uuid_B + '-' + uuid_C + '-' + uuid_D + '-' + uuid_E;
      } else if (this.pattern == '1') {
        // C-D-E-A-B
        var uuid_splitted = this.crypto_uuid.split('-', 5);
        var uuid_C = uuid_splitted[0];
        var uuid_D = uuid_splitted[1];
        var uuid_E = uuid_splitted[2];
        var uuid_A = uuid_splitted[3];
        var uuid_B = uuid_splitted[4];
        this.uuid =
          uuid_A + '-' + uuid_B + '-' + uuid_C + '-' + uuid_D + '-' + uuid_E;
      } else if (this.pattern == '2') {
        // D-E-A-B-C
        var uuid_splitted = this.crypto_uuid.split('-', 5);
        var uuid_D = uuid_splitted[0];
        var uuid_E = uuid_splitted[1];
        var uuid_A = uuid_splitted[2];
        var uuid_B = uuid_splitted[3];
        var uuid_C = uuid_splitted[4];
        this.uuid =
          uuid_A + '-' + uuid_B + '-' + uuid_C + '-' + uuid_D + '-' + uuid_E;
      } else if (this.pattern == '3') {
        // E-A-B-C-D
        var uuid_splitted = this.crypto_uuid.split('-', 5);
        var uuid_E = uuid_splitted[0];
        var uuid_A = uuid_splitted[1];
        var uuid_B = uuid_splitted[2];
        var uuid_C = uuid_splitted[3];
        var uuid_D = uuid_splitted[4];
        this.uuid =
          uuid_A + '-' + uuid_B + '-' + uuid_C + '-' + uuid_D + '-' + uuid_E;
      }
    }
  }

  onKey(data) {
    this.rest.ZipCode(data).subscribe((res) => {
      if (res.results) {
        this.address_1 =
          res.results[0].address1 +
          res.results[0].address2 +
          res.results[0].address3;
      } else if (res.results == null) {
        this.address_1 = '';
        this.translate
          .get(res.message)
          .subscribe(
            (res) => (document.getElementById('error').innerHTML = res)
          );
        document.getElementById('error').style.display = 'block';
      }
    });
  }
  back(id) {
    if (id == 1) {
      this.router.navigate(['/']);
    } else {
      document.getElementById('regConfirm').style.display = 'none';
      document.getElementById('regCompany').style.display = 'block';
    }
  }
	open(content) {
		this.modalService.open(content);
	}
  selectPDF() {
    const input: HTMLElement = document.getElementById('pdffile');
    input.click();
  }

  upload(event: Event) {
    const self: CompanyRegisterComponent = this;
    const reader: FileReader = new FileReader();
    reader.onload = function () {
      var pdf_string = reader.result.toString();
      var pdf_binary = pdf_string.split('data:application/pdf;base64,');
      window.IMAGE_RESULT = pdf_binary[1];
    };
    const element: HTMLInputElement = <HTMLInputElement>event.srcElement;
    if (element.files.length > 0) {
      reader.readAsDataURL(element.files[0]);
      this.pdffile_name = element.files[0].name;
    }
    element.value = '';
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'company-page'
    );
  }

  selectedRadios(id) {
    if (id == 1) {
      this.translate
        .get('COMPANY_REGISTER.COMPANY_REGISTER_PREV_STOCK')
        .subscribe((res) => (this.selectedRadio = res));
      this.corporate_status = '1';
    } else if (id == 2) {
      this.translate
        .get('COMPANY_REGISTER.COMPANY_REGISTER_NEXT_STOCK')
        .subscribe((res) => (this.selectedRadio = res));
      this.corporate_status = '2';
    } else {
      this.translate
        .get('COMPANY_REGISTER.COMPANY_REGISTER_OTHER_STOCK')
        .subscribe((res) => (this.selectedRadio = res));
      this.corporate_status = '3';
    }
  }

  historyRadio(flg) {
    if (flg == 1) {
      this.translate
        .get('COMPANY_REGISTER.COMPANY_REGISTER_USE_USAGE_HISTORY')
        .subscribe((res) => (this.history_flg_radio = res));
      this.history_flg = '1';
    } else {
      this.translate
        .get('COMPANY_REGISTER.COMPANY_REGISTER_NOT_USE_USAGE_HISTORY')
        .subscribe((res) => (this.history_flg_radio = res));
      this.history_flg = '0';
    }
  }

  changeType(type) {
    if (type == 1) {
      this.translate
        .get('COMPANY_REGISTER.COMPANY_REGISTER_CONTRACT_COMPANY_EMPLOYEE_1')
        .subscribe((res) => {
          this.corporation_form = res;
          this.translate
            .get(
              'COMPANY_REGISTER.COMPANY_REGISTER_CONTRACT_COMPANY_EMPLOYEE_2'
            )
            .subscribe(
              (res) => (this.corporation_form = this.corporation_form + res)
            );
        });
      document.getElementById('type1').classList.remove('btn-secondary');
      document.getElementById('type1').classList.add('btn-primary');
      document.getElementById('type2').classList.remove('btn-primary');
      document.getElementById('type2').classList.add('btn-secondary');
      this.corporation = '1';
    } else {
      this.translate
        .get('COMPANY_REGISTER.COMPANY_REGISTER_INSURED_PERSON_1')
        .subscribe((res) => {
          this.corporation_form = res;
          this.translate
            .get('COMPANY_REGISTER.COMPANY_REGISTER_INSURED_PERSON_2')
            .subscribe(
              (res) => (this.corporation_form = this.corporation_form + res)
            );
        });
      document.getElementById('type2').classList.remove('btn-secondary');
      document.getElementById('type2').classList.add('btn-primary');
      document.getElementById('type1').classList.remove('btn-primary');
      document.getElementById('type1').classList.add('btn-secondary');
      this.corporation = '2';
      this.paid_form = '3';
    }
  }

  changeBurden(type) {
    if (type == '3') {
      this.translate
        .get('COMPANY_REGISTER.COMPANY_REGISTER_PERSONAL_BURDEN')
        .subscribe((res) => {
          this.burden_form = res;
        });
      document.getElementById('burden1').classList.remove('btn-secondary');
      document.getElementById('burden1').classList.add('btn-primary');
      document.getElementById('burden2').classList.remove('btn-primary');
      document.getElementById('burden2').classList.add('btn-secondary');
      this.paid_form = '3';
    } else {
      this.translate
        .get('COMPANY_REGISTER.COMPANY_REGISTER_CORPORATE_BURDEN')
        .subscribe((res) => {
          this.burden_form = res;
        });
      document.getElementById('burden2').classList.remove('btn-secondary');
      document.getElementById('burden2').classList.add('btn-primary');
      document.getElementById('burden1').classList.remove('btn-primary');
      document.getElementById('burden1').classList.add('btn-secondary');
      this.paid_form = '1';
    }
  }

  change() {
    document.getElementById('error').style.display = 'none';
  }


  dataValidation() {
    let err = false;
    let errTxt = '';
    let Number = /^-?\d*$/;
    if (window.IMAGE_RESULT == null || window.IMAGE_RESULT == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_PDF_UPLOAD')
        .subscribe((res) => (errTxt = res));
    }
    if(this.corporation == '1'){
      if (this.health_code == null || this.health_code == '') {
        err = true;
        this.translate
          .get('COMPANY_REGISTER_VALIDATION.ERROR_INSURANCE_CARD')
          .subscribe((res) => (errTxt = res));
      }
    }
    if (this.health_code != "" && !Number.test(this.health_code)) {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_INSURANCE_CARD_NO_NUMBER')
        .subscribe((res) => (errTxt = res));
    }
    if (this.memberCount == null || this.memberCount == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_MEMBER_COUNT')
        .subscribe((res) => (errTxt = res));
    } else if (this.memberCount != "" && !Number.test(this.memberCount)) {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_MEMBER_COUNT_NO_NUMBER')
        .subscribe((res) => (errTxt = res));
    }
    if (this.notFirstnameKana && this.firstname_kana !== null && this.firstname_kana !== '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_NOT_KITAGANA_FIRSTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.firstname_kana == null || this.firstname_kana == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_KITAGANA_FIRSTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.notLastnameKana && this.lastname_kana !== null && this.lastname_kana !== '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_NOT_KITAGANA_LASTNAME')
        .subscribe((res) => (errTxt = res));
    }
    
    if (this.lastname_kana == null || this.lastname_kana == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_KITAGANA_LASTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.firstname == null || this.firstname == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_FIRSTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.lastname == null || this.lastname == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_LASTNAME')
        .subscribe((res) => (errTxt = res));
    }
    if (this.business_type == null || this.business_type == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_BUSINESS_TYPE')
        .subscribe((res) => (errTxt = res));
    }
    if (this.telephone == null || this.telephone == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_TELEPHONE')
        .subscribe((res) => (errTxt = res));
    } else if (this.telephone != "" && !Number.test(this.telephone)) {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_TELEPHONE_NO_NUMBER')
        .subscribe((res) => (errTxt = res));
    }
    // if (this.address_3 == null || this.address_3 == '') {
    //   err = true;
    //   this.translate
    //     .get('COMPANY_REGISTER_VALIDATION.ERROR_ADDRESS_3')
    //     .subscribe((res) => (errTxt = res));
    // }
    if (this.address_2 == null || this.address_2 == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_ADDRESS_2')
        .subscribe((res) => (errTxt = res));
    }
    if (this.address_1 == null || this.address_1 == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_ADDRESS_1')
        .subscribe((res) => (errTxt = res));
    }
    if (this.postcode == null || this.postcode == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_POSTCODE')
        .subscribe((res) => (errTxt = res));
    } else if (this.postcode != "" && !Number.test(this.postcode)) {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_POSTCODE_NO_NUMBER')
        .subscribe((res) => (errTxt = res));
    }
    if (this.notKana === true && this.companyNameKana !== null && this.companyNameKana !== '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_COMPANY_NAME_NOT_KANA')
        .subscribe((res) => (errTxt = res));
    }
    if (this.companyNameKana == null || this.companyNameKana == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_COMPANY_NAME_KANA')
        .subscribe((res) => (errTxt = res));
    }

    if (this.companyName == null || this.companyName == '') {
      err = true;
      this.translate
        .get('COMPANY_REGISTER_VALIDATION.ERROR_COMPANY_NAME')
        .subscribe((res) => (errTxt = res));
    }
    if (err) {
      document.getElementById('error').innerHTML = errTxt;
      document.getElementById('error').style.display = 'block';
      return false;
    }
    return true;
  }

  input() {
    if (this.dataValidation() == false) {
      return true;
    } else {
      for(var i = 0; i < this.BusinessType.length; i++){
        if(this.business_type == this.BusinessType[i].code) {
          this.business_name = this.BusinessType[i].name
        }
      }
      document.getElementById('regConfirm').style.display = 'block';
      document.getElementById('regCompany').style.display = 'none';
    }
    this.today = new Date();
    this.app_date = this.datepipe.transform(this.today, 'yyyy-MM-dd');
    this.app_time = this.datepipe.transform(this.today, 'HH:mm:ss');
    var body = {
      data: {
        crypto_uuid: this.crypto_uuid,
        crypto_pattern: this.pattern,
        corporate_status: this.corporate_status,
        company_name:  this.companyName,
        company_name_kana: this.companyNameKana,
        postcode: this.postcode,
        address_1: this.address_1,
        address_2: this.address_2,
        address_3: this.address_3,
        tel: this.telephone,
        staff_last_name: this.lastname,
        staff_first_name: this.firstname,
        staff_last_name_kana: this.lastname_kana,
        staff_first_name_kana: this.firstname_kana,
        staff_post: this.staff_post,
        staff_position: this.staff_position,
        corporation_form: this.corporation,
        users: this.memberCount,
        health_code: this.health_code,
        app_date: this.app_date,
        app_time: this.app_time,
        history_flg: this.history_flg,
      },
    };
  }
  companyRegister() {
    // Upload PDF
    this.spinner.show();
    this.rest.Upload_Regcertificates(this.uuid, window.IMAGE_RESULT).subscribe(
      (res) => {
        this.PDF_REG = res.code;
        if (this.PDF_REG == 'S000001') {
          // Company register
          this.today = new Date();
          this.app_date = this.datepipe.transform(this.today, 'yyyy/MM/dd');
          this.app_time = this.datepipe.transform(this.today, 'HH:mm:ss');
          this.rest.Create_Applications(
            this.crypto_uuid,
            this.pattern,
            this.corporate_status,
            this.companyName,
            this.companyNameKana,
            this.postcode,
            this.address_1,
            this.address_2,
            this.address_3,
            this.telephone,
            this.lastname,
            this.firstname,
            this.lastname_kana,
            this.firstname_kana,
            this.staff_post,
            this.staff_position,
            this.corporation,
            this.memberCount,
            this.app_date,
            this.app_time,
            this.history_flg,
            this.business_type,
            this.health_code,
            this.paid_form
          ).subscribe(
            (res) => {
              this.COMPANY_REG = res.code;
              if(this.COMPANY_REG == 'S000001'){
                this.spinner.hide();
                this.router.navigate(['/confirm']);
              }
            },
            (err) => {
              console.log(err)
              this.router.navigate(['/applied-error',{ code: err.error.error.code },]);
            }
          );
        }
      },
      (err) => {
        if (err.error.error) {
          this.spinner.hide();
          this.router.navigate(['/applied-error', { code: err.error.error.code }]);
        } else {
          this.spinner.hide();
          this.router.navigate(['/applied-error', { code: 'ERR_PDF' }]);
        }
      }
    );
  }
  onKeyKana(katakana) {
    if (!this.isKana(katakana) || katakana.includes('・')) {
      // this.companyNameKana = '';
      this.notKana = true;
    } else {
      this.notKana = false;
    }
  }

  onKeyLastNameKana(katakana) {
    if (!this.isKana(katakana) || katakana.includes('・')) {
      this.notLastnameKana = true;
    } else {
      this.notLastnameKana = false;
    }
  }

  onKeyNameKana(katakana) {
    if (!this.isKana(katakana) || katakana.includes('・')) {
      this.notFirstnameKana = true;
    } else {
      this.notFirstnameKana = false;
    }
  }

  isKana(ch) {
    let k = 0;
    for(let i = 0; i < ch.length; i++) {
      if(ch[i] >= "\u30a0" && ch[i] <= "\u30ff") {
        k++;
      }
    }
    if(k == ch.length) {
      return true;
    }
    return false;
  }
}

declare global {
  interface Window {
    IMAGE_RESULT?: string;
  }
}
