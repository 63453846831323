import { Injectable } from '@angular/core';
import { DataProvider } from '../data/data';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_URL } from '../../environments/environment';

@Injectable()
export class CallapiService {
  private key = 'key';
  constructor(private data: DataProvider, public http: HttpClient) {}

  // A001
  public Create_Sendmail(
    Mail_Address: string,
    CompanyAcquiredCompany: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const body = {
      data: {
        mail_address: Mail_Address,
        acquired_code: CompanyAcquiredCompany,
      },
    };
    return this.http.post(API_URL + '/applicationsreqs/', body, {
      responseType: 'json',
      headers,
    });
  }

  // A002
  public Check_AcquiredCode(CompanyAcquiredCompany: string): Observable<any> {
    const body = {
      data: {
        acquired_code: CompanyAcquiredCompany,
      },
    };
    return this.http.post(API_URL + '/applicationsreqs/check/acquired/', body);
  }
  // A003
  public Check_Applied(crypto_uuid: string, crypto_pattern: string , call_flg: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: this.key,
    });
    const body = {
      data:{
        crypto_uuid: crypto_uuid,
        crypto_pattern: crypto_pattern,
        call_flg: call_flg
      }
    };
    
    return this.http.post(API_URL + '/applicationsreqs/check/id/', body, {
      responseType: 'json',
      headers,
    });
  }
  // B002
  public Get_ApplicationsId(user_uuid: string, uuid: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: this.key,
    });
    const body = {
      data: {
        user_uuid: user_uuid,
        uuid: uuid,
      },
    };
    return this.http.post(API_URL + '/applications/search/id/', body, {
      responseType: 'json',
      headers,
    });
  }
  // B003
  public Create_Applications(
    uuid: string,
    pattern: string,
    corporate_status: string,
    company_name: string,
    company_name_kana: string,
    postcode: string,
    address_1: string,
    address_2: string,
    address_3: string,
    tel: string,
    staff_last_name: string,
    staff_first_name: string,
    staff_last_name_kana: string,
    staff_first_name_kana: string,
    staff_post: string,
    staff_position: string,
    corporation_form: string,
    users: string,
    app_date: string,
    app_time: string,
    history_flg: string,
    business_type: string,
    health_code: string,
    paid_form: string,
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: this.key,
    });
    const body = {
      data: {
        crypto_uuid: uuid,
        crypto_pattern: pattern,
        corporate_status: corporate_status,
        company_name: company_name,
        company_name_kana: company_name_kana,
        postcode: postcode,
        address_1: address_1,
        address_2: address_2,
        address_3: address_3,
        tel: tel,
        staff_last_name: staff_last_name,
        staff_first_name: staff_first_name,
        staff_last_name_kana: staff_last_name_kana,
        staff_first_name_kana: staff_first_name_kana,
        staff_post: staff_post,
        staff_position: staff_position,
        corporation_form: corporation_form,
        users: users,
        app_date: app_date,
        app_time: app_time,
        history_flg: history_flg,
        business_type: business_type,
        health_code: health_code,
        paid_form: paid_form,
      },
    };
    return this.http.post(API_URL + '/applications/', body, {
      responseType: 'json',
      headers: headers,
    });
  }
  // B004
  public Update_Applications(
    uuid: string,
    crypto_pattern: string,
    corporate_status: string,
    company_name: string,
    company_name_kana: string,
    postcode: string,
    address_1: string,
    address_2: string,
    address_3: string,
    tel: string,
    staff_last_name: string,
    staff_first_name: string,
    staff_last_name_kana: string,
    staff_first_name_kana: string,
    staff_post: string,
    staff_position: string,
    judge_state: string,
    corporation_form: string,
    users: string,
    history_flg: string,
    mail_send_flg: string,
    business_type: string,
    health_code: string,
    paid_form: string,
    fees: number
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: this.key,
    });
    const body = {
      data: {
        uuid: uuid,
        crypto_pattern: crypto_pattern,
        corporate_status: corporate_status,
        company_name: company_name,
        company_name_kana: company_name_kana,
        postcode: postcode,
        address_1: address_1,
        address_2: address_2,
        address_3: address_3,
        tel: tel,
        staff_last_name: staff_last_name,
        staff_first_name: staff_first_name,
        staff_last_name_kana: staff_last_name_kana,
        staff_first_name_kana: staff_first_name_kana,
        staff_post: staff_post,
        staff_position: staff_position,
        judge_state: judge_state,
        corporation_form: corporation_form,
        users: users,
        history_flg: history_flg,
        mail_send_flg: mail_send_flg,
        business_type: business_type,
        health_code: health_code,
        paid_form: paid_form,
        fees: fees,
      },
    };
    return this.http.post(API_URL + '/applications/id/', body, {
      responseType: 'json',
      headers: headers,
    });
  }
  // C001
  public Download_Regcertificates(useruuid: string, uuid: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: this.key,
    });
    const body = {
      data: {
        user_uuid: useruuid,
        uuid: uuid,
      },
    };
    return this.http.post(API_URL + 'regcertificates/download/', body, {
      responseType: 'json',
      headers,
    });
  }
  // C002
  public Upload_Regcertificates(
    uuid: string,
    pdf_binary: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      authorization: this.key,
    });
    const body = {
      data: {
        uuid: uuid,
        pdf_binary: pdf_binary,
      },
    };
    return this.http.post(API_URL + '/regcertificates/upload/', body, {
      headers: headers,
      responseType: 'json',
    });
  }
  // Post Code Japanese
  public ZipCode(PostCode: string): Observable<any> {
    return this.http.jsonp(
      'https://zipcloud.ibsnet.co.jp/api/search?zipcode=' + PostCode,
      'callback'
    );
  }
}
