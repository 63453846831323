import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'guidance',
  templateUrl: './guidance.component.html',
  styleUrls: ['./guidance.component.scss'],
})
export class GuidanceComponent implements OnInit {
public companyName = '';
public companyName1 ='の皆様';
public fee1;
public multifee1;
public promotion;
public fee2;
public multifee2;
public entryfee;
public cnt = 10;
public guideText = 'ご登録に必要なもの';
public guideText1 = '●JOYFIT 法人会員証明書　●ご本人確認書類（健康保険証）●キャッシュカード';
public guideText2 = '※JOYFIT法人会員証明書は、従業員個人様の情報記入と会社・組合認印が必要です。※証明書は会社・組合より発行されます。';
  constructor(
    private renderer: Renderer2,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService:Title,
  ) {
    this.titleService.setTitle("JOYFIT 法人入会案内");
  }
  public logoUrl;

 slice(data){
  var list = [];
  var k = data - 1;
    for (var i = 1, j = 1; i <= this.companyName.length; i++, j++) {
      i = i + k;
      j = j + k;
      list.push(this.companyName.substring(j - data, i));
    }
  return list;
 }
  
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params == null || params == undefined) {
        this.router.navigate(['/company-register']);
      }
      this.companyName = params['company_name'];
      this.fee1 = params['fees1'];
      this.fee2 = params['fees2'];
      this.entryfee = params['entryfee'];
      this.promotion = params['promotion'];
    });
    if(this.companyName != undefined){
      var fontsize = 50;
      var substrings = this.slice(this.cnt);
      const canvas = <HTMLCanvasElement> document.getElementById("canvas");
      const ctx = canvas.getContext("2d");
      canvas.height = 240;
      const x = canvas.width / 2;
      var y = canvas.height;
      ctx.beginPath();
      ctx.moveTo(x, 0);
      ctx.lineTo(x, canvas.height);
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";
      ctx.textAlign = "center";
       for(let i = 0; i < substrings.length; i++){
        let metrics = ctx.measureText(substrings[i]);
        if(metrics.width > 60){
          substrings = this.slice(this.cnt = substrings[i].length / 2);
         } 
       }


      while( (substrings.length * fontsize) > 240 ){
        substrings = this.slice(this.cnt = this.cnt + 1);
        fontsize =  fontsize - 6;
      }

      ctx.font =  fontsize + "px sans-serif";
      if(substrings.length % 2 == 0) {
        var a = substrings.length/2;
        substrings.forEach((substrings, index) => {
           y= canvas.height/2;
          ctx.fillText(substrings, x, y + (index- a) * fontsize + fontsize - 10);
        });
  
      } else {
        var a = Math.floor(substrings.length/2);
        y = canvas.height/2;
        substrings.forEach((substrings, index) => {
          ctx.fillText(substrings, x, y + (index - a) * fontsize + fontsize - 20);
        });
      }
    } else {
      this.companyName = ''
    }

    if(this.fee1 == undefined){
      this.fee1 = 0;
    } 
    if(this.entryfee == undefined){
      this.entryfee = 0;
    } 
    if(this.fee1 == 0 &&  this.entryfee == 0){
      this.multifee1 = Math.floor(parseInt(this.fee1) * 1.1);
      var canvasText = 'お勤め先にお問い合わせください';
      const canvas2 = <HTMLCanvasElement> document.getElementById('canvas2');
      var ctx2 = canvas2.getContext("2d");
      canvas2.width =750;
      const x2 = canvas2.width / 2;
      ctx2.beginPath();
      ctx2.moveTo(x2, 0);
      ctx2.lineTo(x2, canvas2.height);
      ctx2.font = "20px sans-serif";
      ctx2.textAlign = "center";
      ctx2.fillText(canvasText, x2, 80);
    } else {
      this.multifee1 = Math.floor(parseInt(this.fee1) * 1.1);
      var canvasText = this.fee1 + '円（税込' + this.multifee1 + '円）～  ※別途、入会金' + this.entryfee + '円（税別）／人が必要です。'
      const canvas2 = <HTMLCanvasElement> document.getElementById('canvas2');
      var ctx2 = canvas2.getContext("2d");
      canvas2.width =750;
      const x2 = canvas2.width / 2;
      ctx2.beginPath();
      ctx2.moveTo(x2, 0);
      ctx2.lineTo(x2, canvas2.height);
      ctx2.font = "20px sans-serif";
      ctx2.textAlign = "center";
      ctx2.fillText(canvasText, x2, 80);
    }

    if(this.promotion == undefined){
      this.promotion = '';
    }

    const canvas3 = <HTMLCanvasElement> document.getElementById('canvas3');
    var ctx3 = canvas3.getContext("2d");
    canvas3.width =750;
    const x3 = canvas3.width / 2;
    ctx3.beginPath();
    ctx3.moveTo(x3, 0);
    ctx3.lineTo(x3, canvas3.height);
    ctx3.font = "70px sans-serif";
    ctx3.textAlign = "center";
    ctx3.fillText(this.promotion, x3, 80);
  }
}
