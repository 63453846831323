import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CallapiService } from 'src/providers/rest/callapi.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
})
export class Requests implements OnInit, OnDestroy {
  public crypto_uuid;
  public pattern;
  public uuid;
  public JUDGE_DISP_CAUSE;
  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private router: Router,
    public translate: TranslateService,
    private route: ActivatedRoute,
    public rest: CallapiService,
    private spinner: NgxSpinnerService,
    private titleService:Title,
  ) {
    this.titleService.setTitle("JOYFIT 法人契約申込み・再審査フォーム");
  }
  public logoUrl;
  ngOnInit() {
    this.logoUrl = environment.production ? 'assets/img/fit365_logo.svg' : 'assets/img/fit365_logo_test_mode.svg';
    this.renderer.addClass(document.querySelector('app-root'), 'company-page');
    this.route.queryParams.subscribe((params) => {
      if (params == null || params == undefined) {
        this.router.navigate(['/company-register-edit']);
      }
      this.crypto_uuid = params['id'];
      this.pattern = params['cpn'];
      if (
        this.crypto_uuid == undefined ||
        this.crypto_uuid == '' ||
        (this.crypto_uuid == null && this.pattern == undefined) ||
        this.pattern == '' ||
        this.pattern == null
      ) {
        this.crypto_uuid = this.route.snapshot.paramMap.get('id');
        this.pattern = this.route.snapshot.paramMap.get('cpn');
      }
    });
    if (this.crypto_uuid) {
      if (this.pattern == '0') {
        // B-C-D-E-A
        var uuid_splitted = this.crypto_uuid.split('-', 5);
        var uuid_B = uuid_splitted[0];
        var uuid_C = uuid_splitted[1];
        var uuid_D = uuid_splitted[2];
        var uuid_E = uuid_splitted[3];
        var uuid_A = uuid_splitted[4];
        this.uuid =
          uuid_A + '-' + uuid_B + '-' + uuid_C + '-' + uuid_D + '-' + uuid_E;
      } else if (this.pattern == '1') {
        // C-D-E-A-B
        var uuid_splitted = this.crypto_uuid.split('-', 5);
        var uuid_C = uuid_splitted[0];
        var uuid_D = uuid_splitted[1];
        var uuid_E = uuid_splitted[2];
        var uuid_A = uuid_splitted[3];
        var uuid_B = uuid_splitted[4];
        this.uuid =
          uuid_A + '-' + uuid_B + '-' + uuid_C + '-' + uuid_D + '-' + uuid_E;
      } else if (this.pattern == '2') {
        // D-E-A-B-C
        var uuid_splitted = this.crypto_uuid.split('-', 5);
        var uuid_D = uuid_splitted[0];
        var uuid_E = uuid_splitted[1];
        var uuid_A = uuid_splitted[2];
        var uuid_B = uuid_splitted[3];
        var uuid_C = uuid_splitted[4];
        this.uuid =
          uuid_A + '-' + uuid_B + '-' + uuid_C + '-' + uuid_D + '-' + uuid_E;
      } else if (this.pattern == '3') {
        // E-A-B-C-D
        var uuid_splitted = this.crypto_uuid.split('-', 5);
        var uuid_E = uuid_splitted[0];
        var uuid_A = uuid_splitted[1];
        var uuid_B = uuid_splitted[2];
        var uuid_C = uuid_splitted[3];
        var uuid_D = uuid_splitted[4];
        this.uuid =
          uuid_A + '-' + uuid_B + '-' + uuid_C + '-' + uuid_D + '-' + uuid_E;
      }
    }
    this.spinner.show();
    this.rest.Get_ApplicationsId(this.uuid, this.uuid).subscribe((res) => {
      this.JUDGE_DISP_CAUSE = res.data.judge_disp_cause;
      this.spinner.hide();
    });
  }

  registeredit() {
    this.router.navigate([
      '/company-register-edit',
      { id: this.crypto_uuid, cpn: this.pattern },
    ]);
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'company-page'
    );
  }
}
