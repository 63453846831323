import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppService } from 'src/app/utils/services/app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit, OnDestroy {
  public registerForm: FormGroup;
  constructor(
    private renderer: Renderer2,
    private toastr: ToastrService,
    private appService: AppService,
    private router: Router,
    public translate: TranslateService,
    private titleService:Title,
  ) {
    this.titleService.setTitle("JOYFIT 法人契約申込み・再審査フォーム");
  }

  
  public logoUrl;
  ngOnInit() {
    this.logoUrl = environment.production ? 'assets/img/fit365_logo.svg' : 'assets/img/fit365_logo_test_mode.svg';
    this.renderer.addClass(document.querySelector('app-root'), 'company-page');
    this.registerForm = new FormGroup({
      email: new FormControl(null, Validators.required)
    });
    history.pushState(null, document.title, location.href);
  }

  ngOnDestroy() {
    this.renderer.removeClass(
      document.querySelector('app-root'),
      'company-page'
    );
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    history.pushState(null, document.title, location.href);
    // window.history.forward();
  }
}
